import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { openPopupWidget } from "react-calendly"

import HeaderFlat from "../components/HeaderFlat"
import FooterGlobal from "../components/FooterGlobal"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MainNav from "../components/MainNav"
// import ScheduleCallBtn from "../components/ScheduleCallBtn"

import { FaCheckCircle } from "react-icons/fa"
import { FaAngleDoubleRight } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"

export const getoData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "free-strategy-call") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const StrategyCall = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  const [show, setShow] = useState(0)

  const handleAccordion = id => {
    if (show === id) {
      setShow(0)
    } else {
      setShow(id)
    }
  }

  const handleCalendly = () => {
    console.log(`test`)
    const url = "https://calendly.com/rayjenscode/15"
    openPopupWidget({ url })
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav />
        <HeaderFlat
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}

        // background={heroBG}
        // contact={contactNumber}
        />

        <section className="strategy-call">
          <div className="strategy-call__header container">
            <div className="strategy-call__header__left">
              <p className="strategy-call__header__left--note">
                SPOTS ARE LIMITED
              </p>
              <h1 className="strategy-call__header__left--heading">
                Free 30-Minutes Website Strategy Call with Raymond
              </h1>
              <h2 className="strategy-call__header__left--subheading">
                Get your 100% FREE no-obligation Website Strategy Call (PHP5,000
                Value) that could easily help your current website{" "}
                <span className="strategy-call__header__left--span">
                  start generating thousands of pesos in extra sales
                </span>{" "}
                for your company.
              </h2>

              <button
                className="mt-3 btn btn-secondary-outline btn-large text-normal "
                onClick={handleCalendly}
              >
                Schedule My Call Now
              </button>
            </div>

            <div className="strategy-call__header__right">
              <div className="strategy-call__header__right--imgWrapper">
                <img
                  src={require("../images/ray.jpg")}
                  alt=""
                  className="strategy-call__header__right--img"
                />
              </div>
            </div>
          </div>

          <div className="strategy-call__cover container">
            <h2 className="strategy-call__cover--heading">
              Here's What We Will Cover...
            </h2>

            <div className="strategy-call__diagnosis--col">
              <div className="strategy-call__diagnosis--left">
                <div className="strategy-call__diagnosis--left--imgWrapper">
                  <img
                    src={require("../images/diagnosis.png")}
                    alt="Great Web Audit"
                    className="strategy-call__diagnosis--left--img"
                  />
                </div>
              </div>
              <div className="strategy-call__diagnosis--right">
                <h3 className="strategy-call__diagnosis--right--heading">
                  Complete{" "}
                  <span className="strategy-call__diagnosis--right--heading--span">
                    Website Diagnosis
                  </span>
                </h3>
                <p className="strategy-call__diagnosis--right--subheading">
                  I will take an in-depth look into every facet of your
                  website/sales funnel and show you:
                </p>
                <ul className="strategy-call__diagnosis--right--list">
                  <li className="strategy-call__diagnosis--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__diagnosis--right--itemIcon" />
                    </div>
                    <span>
                      Costly website mistakes that are scaring off customers.
                    </span>
                  </li>
                  <li className="strategy-call__diagnosis--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__diagnosis--right--itemIcon" />
                    </div>
                    <span>
                      Bleed points that are distracting visitors from taking
                      action and becoming customers.
                    </span>
                  </li>
                  <li className="strategy-call__diagnosis--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__diagnosis--right--itemIcon" />
                    </div>
                    <span>
                      Layout recommendations to increase calls and form
                      submissions.
                    </span>
                  </li>
                  <li className="strategy-call__diagnosis--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__diagnosis--right--itemIcon" />
                    </div>
                    <span>
                      Verify that your website is properly listing and crawled
                      by Google.
                    </span>
                  </li>
                  <li className="strategy-call__diagnosis--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__diagnosis--right--itemIcon" />
                    </div>
                    <span>
                      Traffic analytics and red flags that are hurting site
                      traffic.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="strategy-call__ranking--col">
              <div className="strategy-call__ranking--right">
                <h3 className="strategy-call__ranking--right--heading">
                  <span className="strategy-call__ranking--right--heading--span">
                    Google Ranking{" "}
                  </span>
                  Overview
                </h3>
                <p className="strategy-call__ranking--right--subheading">
                  I will run an SEO ranking audit on your website and other web
                  mentions of your business and show you:
                </p>
                <ul className="strategy-call__ranking--right--list">
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>
                      Your current ranking breakdown and website health score.
                    </span>
                  </li>
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>Competitor ranking analysis.</span>
                  </li>
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>
                      Free Google my business optimization assistance (PHP 5,000
                      value)
                    </span>
                  </li>
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>
                      How to find out if your web designer screwed up your
                      chances to rank on google
                    </span>
                  </li>
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>
                      Other actionable SEO recommendations that will boost your
                      ranking and drive new traffic.
                    </span>
                  </li>
                  <li className="strategy-call__ranking--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__ranking--right--itemIcon" />
                    </div>
                    <span>+ More</span>
                  </li>
                </ul>
              </div>

              <div className="strategy-call__ranking--left">
                <div className="strategy-call__ranking--left--imgWrapper">
                  <img
                    src={require("../images/ranking.png")}
                    alt="Great Web Audit"
                    className="strategy-call__ranking--left--img"
                  />
                </div>
              </div>
            </div>

            <div className="strategy-call__listing--col">
              <div className="strategy-call__listing--left">
                <div className="strategy-call__listing--left--imgWrapper">
                  <img
                    src={require("../images/local-listing.png")}
                    alt="Great Web Audit"
                    className="strategy-call__listing--left--img"
                  />
                </div>
              </div>
              <div className="strategy-call__listing--right">
                <h3 className="strategy-call__listing--right--heading">
                  <span className="strategy-call__listing--right--heading--span">
                    Local listing{" "}
                  </span>
                  Scan
                </h3>
                <p className="strategy-call__listing--right--subheading">
                  Local listings are one of the most important ranking factors
                  for local businesses yet less than 1% of local businesses have
                  all their listings up-to-date. I can perfect your listings for
                  you to put you miles ahead of your competition.
                </p>
                <ul className="strategy-call__listing--right--list">
                  <li className="strategy-call__listing--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__listing--right--itemIcon" />
                    </div>
                    <span>
                      Free scan of over 100+ local listings (PHP 3,000 value)
                    </span>
                  </li>
                  <li className="strategy-call__listing--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__listing--right--itemIcon" />
                    </div>
                    <span>
                      Full report of listings that you are missing and
                      inconsistencies between listings.
                    </span>
                  </li>
                  <li className="strategy-call__listing--right--item">
                    <div>
                      <FaCheckCircle className="strategy-call__listing--right--itemIcon" />
                    </div>
                    <span>
                      Full list of free listings you can create on your own to
                      increase visibility.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="strategy-call__conclusion container">
            <h2 className="strategy-call__conclusion--heading">
              Even if you don't have a website yet, you'll be getting a fully
              customized breakdown of my proven system for local growth....
            </h2>
            <p className="strategy-call__conclusion--subheading">
              I dive deep into your business' online processes using tracking
              and auditing software to create an advanced analysis of your
              current performance and what you need to do to drastically
              increase your efficiency on the internet.
            </p>

            <div className="strategy-call__conclusion--imgWrapper">
              <img
                src={require("../images/seo-stuff.png")}
                alt="Great Web Audit"
                className="strategy-call__conclusion--img"
              />
            </div>
            <button
              className="strategy-call__conclusion--btn btn btn-large btn-secondary-outline text-normal mt-3"
              onClick={handleCalendly}
            >
              Schedule My Call Now
            </button>
            {/* <ScheduleCallBtn center="true" onClick={handleCalendly} /> */}
          </div>

          <div className="strategy-call__qualification container">
            <h3 className="strategy-call__qualification--heading">
              Is This Call{" "}
              <span className="strategy-call__qualification--heading--span">
                For You
              </span>
              ...?
            </h3>
            <div className="strategy-call__qualification--col">
              <div className="strategy-call__qualification--left">
                <h3 className="strategy-call__qualification--left--heading">
                  <span className="strategy-call__qualification--span">
                    Who
                  </span>{" "}
                  is This For?
                </h3>
                <p className="strategy-call__qualification--left--subheading">
                  This type of growth call is for owners and managers of law
                  firms, dental, professional service, retail, or construction
                  business who:
                </p>
                <ul className="strategy-call__qualification--list">
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>Have an old, outdated website.</span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      Are conisdering a new website or a website update.
                    </span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      {" "}
                      Are having struggles getting clients or customers online.
                    </span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span> Aren't showing up on Google.</span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      {" "}
                      Have no clue where to start with your online presence.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="strategy-call__qualification--right">
                <h3 className="strategy-call__qualification--right--heading">
                  <span className="strategy-call__qualification--span">
                    What{" "}
                  </span>{" "}
                  Will You Get?
                </h3>
                <p className="strategy-call__qualification--right--subheading">
                  This is NOT a sales call. This call is designed to help you:
                </p>
                <ul className="strategy-call__qualification--list">
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      {" "}
                      Know the most cost-effective gameplan for your business.
                    </span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span> Understand why your website isn't working.</span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      {" "}
                      Know what to do to start getting customers/clients through
                      your website.
                    </span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      Full strategy blueprint to follow to find success online.
                    </span>
                  </li>
                  <li className="strategy-call__qualification--item">
                    <div>
                      <FaAngleDoubleRight className="strategy-call__qualification--icon" />
                    </div>
                    <span>
                      {" "}
                      Free online business consulting. (PHP 7,000 value)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="strategy-call__qualification--imgWrapper">
                <img
                  className="strategy-call__qualification--img"
                  src={require("../images/guarantee.png")}
                  alt="Great Web"
                />
              </div>
              <p className="strategy-call__qualification--footer">
                I guarantee that this strategy call will supercharge your
                business if you are willing to put into action the points that
                we talk about on the call. If I can't find some way to
                significantly increase your traffic, leads, or sales, I will
                give you PHP 25,000 worth of free work and consulting! This
                means you will get over 10 hours of one-on-one assistance at no
                charge.
              </p>
            </div>
          </div>

          <div className="strategy-call__faq container">
            <h3 className="strategy-call__faq--heading">
              Frequently Asked Questions
            </h3>
            <ul className="strategy-call__faq--list">
              <li className="strategy-call__faq--item">
                <h4
                  className="strategy-call__faq--item--question"
                  onPointerDown={() => handleAccordion(1)}
                >
                  <div>
                    {show === 1 ? (
                      <FaMinus className="strategy-call__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="strategy-call__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="strategy-call__faq--item--question--span">
                    Will I be pressured to pay for your services?
                  </span>
                </h4>
                {/* {show === 1 && ( */}
                <div
                  className={`${
                    show === 1
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="strategy-call__faq--item--description">
                    Not at all. This is a no-pressure, no-obligation call. We
                    will talk strictly about ways to improve your website and
                    grow your business online. I never want to pressure anyone
                    into becoming a client. I work closely with each one of my
                    clients and I prefer that they choose to work with my by
                    their own free will. The clients that do this are always the
                    easiest to work with and inevitably see the greatest results
                    for their business. This call will be a great way for us to
                    get to know each other and for me to offer my expertise to
                    help your business grow. If you feel like you would like to
                    hire me any time after the call, this is always an option.
                  </p>
                </div>

                {/* )} */}
              </li>
              <li className="strategy-call__faq--item">
                <h4
                  className="strategy-call__faq--item--question"
                  onPointerDown={() => handleAccordion(2)}
                >
                  <div>
                    {show === 2 ? (
                      <FaMinus className="strategy-call__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="strategy-call__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="strategy-call__faq--item--question--span">
                    Will there be information withheld from me if I don't hire
                    you?
                  </span>
                </h4>
                {/* {show === 2 && ( */}
                <div
                  className={`${
                    show === 2
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="strategy-call__faq--item--description">
                    No way. I won't use this call to bait you with obvious
                    information and then hold the quality info for ransom until
                    you hire me. I will hold nothing back and there is no catch.
                  </p>
                </div>

                {/* )} */}
              </li>
              <li className="strategy-call__faq--item">
                <h4
                  className="strategy-call__faq--item--question"
                  onPointerDown={() => handleAccordion(3)}
                >
                  <div>
                    {show === 3 ? (
                      <FaMinus className="strategy-call__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="strategy-call__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="strategy-call__faq--item--question--span">
                    {" "}
                    Why do you offer this if you don't make any money off of it?
                  </span>
                </h4>
                {/* {show === 3 && ( */}
                <div
                  className={`${
                    show === 3
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="strategy-call__faq--item--description">
                    To be completely honest, many business owners that take
                    advantage of this free strategy call want to hire me to grow
                    their business once they see the results of what I share on
                    the call. However, I don't expect it and I won't ever be
                    offended if you are just looking for some free help for the
                    time being.
                  </p>
                </div>

                {/* )} */}
              </li>
              <li className="strategy-call__faq--item">
                <h4
                  className="strategy-call__faq--item--question"
                  onPointerDown={() => handleAccordion(4)}
                >
                  <div>
                    {show === 4 ? (
                      <FaMinus className="strategy-call__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="strategy-call__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="strategy-call__faq--item--question--span">
                    How long will these be available?
                  </span>
                </h4>
                {/* {show === 4 && ( */}
                <div
                  className={`${
                    show === 4
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="strategy-call__faq--item--description">
                    These strategy sessions will not always be available. I will
                    offer them only when I have time available on my schedule.
                    As client work piles up(and my client's work is top
                    priority), I will not have time to offer these anymore so I
                    recommend you take advantage now.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="strategy-call__faq--item">
                <h4
                  className="strategy-call__faq--item--question"
                  onPointerDown={() => handleAccordion(5)}
                >
                  <div>
                    {show === 5 ? (
                      <FaMinus className="strategy-call__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="strategy-call__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="strategy-call__faq--item--question--span">
                    What kind of results could I see from the info shared on
                    this call?
                  </span>
                </h4>
                {/* {show === 5 && ( */}
                <div
                  className={`${
                    show === 5
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="strategy-call__faq--item--description">
                    While the results will vary depending on your business,
                    those that utilize one of my strategy calls typically see
                    results almost immediately. Most are able to see a notable
                    increase in traffic, website inquiries, and direct calls to
                    their business.
                  </p>
                  <p className="strategy-call__faq--item--description">
                    That being said, much of this depends on the current state
                    of your website and your willingness to implement the
                    changes that are discussed on our call.
                  </p>
                </div>
                {/* )} */}
              </li>
            </ul>
            <div className="strategy-call__ready">
              <h4 className="strategy-call__ready--heading">
                Ready to Get Started?
              </h4>
              <p className="strategy-call__ready--subheading">
                If you want to learn more about how I can help grow your
                business, click below to schedule a free growth call. If you are
                ready to start right away,{" "}
                <Link className="strategy-call__ready--link" to="/contact">
                  click here to contact me.
                </Link>
              </p>

              <button
                className="mt-3 btn btn-secondary-outline btn-large text-normal"
                onClick={handleCalendly}
              >
                Schedule Call Now - It's Free
              </button>

              {/* <ScheduleCallBtn center="true" onClick={handleCalendly} /> */}
            </div>
          </div>
        </section>

        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default StrategyCall
